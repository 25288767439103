import React from 'react';
import { graphql } from "gatsby"
import Layout from '../layouts/Layout'
import SiteMetadata from '../components/SiteMetadata';

const AppTutorialMacOnePage = ({ data, location }) => {
    return (
        <Layout data={data.meta} location={location}>
            <SiteMetadata title="App Tutorial Mac 1" />
            <div className="container h-screen py-32 mx-auto text-center">
            <h1 className="text-5xl lg:text-7xl">App Tutorial Mac 1</h1>
            </div>
        </Layout>
    );
};

export default AppTutorialMacOnePage;

export const query = graphql`
  query AppTutorialMacOneEnQuery {
    meta: site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`